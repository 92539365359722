$(document).ready(function () {

    if($('#modal-overlay').length > 0){
        var cookieId = $('#modal-overlay').data('messageid').toString();
        if (!Cookies.get(cookieId)) {
            Cookies.set(cookieId, 'show', { expires : 7});
        } else {
            if (Cookies.get(cookieId) == 'hide') {
                $('#modal-overlay').remove();
            }
        }
        $('#modal-overlay .close-dialog').click(function (e) {
            Cookies.set(cookieId, 'hide');
            $('#modal-overlay').remove();
            e.preventDefault();
            return false;
        })
        $('#modal-overlay .button').click(function (e) {
            Cookies.set(cookieId, 'hide');
            $('#modal-overlay').remove();
            e.preventDefault();
            location.href = $(this).attr('href');
            return false;
        })

    }
});