$(function () {
    // Initialise slick sliders
    $('.slideshow').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 6000,
        variableWidth: false,
        centerMode: true,
        pauseOnHover: false,
    });

    $('.reviewSlideshow').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 30000
    });

    $('.slideshow').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if (1 == nextSlide) {
            //console.log("setting slider speed to 1000");
            slick.slickSetOption("autoplaySpeed", 4000);
        };
    });
});

